<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex md4 sm8 xs12>
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Gratis registreren</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="register" v-model="valid">
              <v-text-field
                  :rules="nameRules"
                  label="Naam"
                  prepend-icon="person"
                  v-model="name"
              ></v-text-field>
              <v-text-field
                  :rules="emailRules"
                  label="Email"
                  prepend-icon="mail"
                  v-model="email"
              ></v-text-field>
              <v-text-field
                  :rules="phoneNumberRules"
                  label="Telefoonnummer"
                  prepend-icon="phone"
                  v-model="phoneNumber"
              ></v-text-field>
              <v-text-field
                  :rules="passwordRules"
                  id="password"
                  label="Wachtwoord"
                  prepend-icon="lock"
                  type="password"
                  v-model="password"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn :disabled="!valid" color="primary" type="submit"
              >Register
              </v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SessionApi from '@/api/SessionApi'

export default {
  data: () => ({
    valid: false,
    nameRules: [(v) => !!v || 'Naam is verplicht'],
    emailRules: [
      (v) => !!v || 'Email is verplicht',
      (v) => /.+@.+/.test(v) || 'Ongeldige email adres',
    ],
    phoneNumberRules: [
      (v) => !!v || 'Telefoonnummer is verplicht',
      (v) => (!!v && v.length >= 10) || 'Telefoonnummer is te kort',
    ],
    passwordRules: [
      (v) => !!v || 'Wachtwoord is verplicht',
      (v) => (!!v && v.length >= 5) || 'Wachtwoord is te kort',
    ],
    name: null,
    email: null,
    password: null,
    phoneNumber: '',
  }),
  methods: {
    register: function () {
      if (!this.valid) return false

      let user = {
        name: this.name,
        email: this.email,
        password: this.password,
        phone_number: this.phoneNumber,
      }

      SessionApi.register(user)
        .then(() => {
          this.$store.dispatch('addFlashMessage', {
            type: 'success',
            message: 'Registratie gelukt, u kunt nu inloggen',
          })
          this.$router.push({ name: 'login' })
        })
        .catch((error) => console.error(error))
    },
  },
}
</script>
